import axios from 'axios'
import querystring from 'querystring'

import API from '../../components/API'
import { getJwtString } from '../../components/Auth'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getPatients (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`

  return axios.get(`${API('patients')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
