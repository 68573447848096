import React, { useState, useEffect } from 'react'
import querystring from 'query-string'
import { toast } from 'react-toastify'
import moment from 'moment'
import { navigate } from 'gatsby'

import './remit-session.scss'
import arrowIcon from '../images/arrow-white.svg'

import Layout from '../components/Layout'
import { getPatients } from '../services/api/patient'
import { getSession } from '../services/api/sessions'
import { getPopulations } from '../services/api/populations'
import { getEps } from '../services/api/eps'
import { getEducationLevels } from '../services/api/education-levels'
import { getRanks } from '../services/api/get-ranks'
import { getOrganigrams } from '../services/api/organigrams'
import { getFromPivot } from '../services/api/pivots'
import { getReasons } from '../services/api/reasons'
import { getPrecipitants } from '../services/api/precipitants'
import { getStrategies } from '../services/api/strategies'
import { getResponseChannels } from '../services/api/response-channels'
import { getDiagnostics } from '../services/api/get-diagnostics'
import { getProblematics } from '../services/api/problematics'
import { getProcesses } from '../services/api/processes'
import { getProcessResults } from '../services/api/process-results'

import {
  getRemissions,
  putRemission,
  putCheckRemission
} from '../services/api/remissions'

import jwt, {
  hasPermission,
  isBrowser,
  rolesHasOneOf
} from '../components/Auth'

/**
 * Reporte de remisión
 */
export default function RemitSessionPage (props) {
  const queryParams = querystring.parse(props.location.search)
  const [session, setSession] = useState({})
  const [patient, setPatient] = useState({})
  const [population, setPopulation] = useState({})
  const [eps, setEps] = useState({})
  const [educationLevel, setEducationLevel] = useState({})
  const [rank, setRank] = useState({})
  const [organigram, setOrganigram] = useState({})
  const [selectedReasons, setSelectedReasons] = useState([])
  const [selectedPrecipitants, setSelectedPrecipitants] = useState([])
  const [selectedStrategies, setSelectedStrategies] = useState([])
  const [selectedResponseChannels, setSelectedResponseChannels] = useState([])
  const [selectedDiagnostics, setSelectedDiagnostics] = useState([])
  const [receiver, setReceiver] = useState({})
  const [remitter, setRemitter] = useState({})
  const [problematic, setProblematic] = useState({})
  const [process, setProcess] = useState({})
  const [processResult, setProcessResult] = useState({})
  const [remission, setRemission] = useState({})
  const [canEdit, setCanEdit] = useState(false)
  const [serviceTime, setServiceTime] = useState('0 días')

  // datasets
  const [reasons, setReasons] = useState([])
  const [precipitants, setPrecipitants] = useState([])
  const [strategies, setStrategies] = useState([])
  const [responseChannels, setResponseChannels] = useState([])
  const [diagnostics, setDiagnostics] = useState([])

  // get session
  useEffect(() => {
    if (!queryParams.session_id) return

    getSession(queryParams.session_id)
      .then(res => {
        if (res.status === 200) {
          setSession(res.data[0])
        } else if (res.status === 204) {
          toast.error(`no existe la sesión ${queryParams.session_id}`)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get reasons
  useEffect(() => {
    getReasons()
      .then(res => {
        if (res.status === 200) {
          setReasons(res.data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get precipitants
  useEffect(() => {
    getPrecipitants()
      .then(res => {
        if (res.status === 200) {
          setPrecipitants(res.data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get strategies
  useEffect(() => {
    getStrategies()
      .then(res => {
        if (res.status === 200) {
          setStrategies(res.data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get response channels (canales de respuesta predominante)
  useEffect(() => {
    getResponseChannels()
      .then(res => {
        if (res.status === 200) {
          setResponseChannels(res.data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get diagnostics
  useEffect(() => {
    getDiagnostics()
      .then(res => {
        if (res.status === 200) {
          setDiagnostics(res.data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // get remission data
  useEffect(() => {
    if (!session.id) return

    getRemissions({ session_id: session.id })
      .then(res => {
        if (res.status === 200) {
          const remiss = res.data.remissions[0]
          const users = res.data.users

          setRemission(remiss)
          setReceiver(users.find(item => item.id === remiss.receiver))
          setRemitter(users.find(item => item.id === remiss.remitter))

          // update_remit_session (id=110)
          setCanEdit(
            hasPermission(110) || jwt().payload.user_id === remiss.remitter
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get patient
  useEffect(() => {
    if (!session.patient_id) return

    getPatients({ id: session.patient_id })
      .then(res => {
        if (res.status === 200) {
          const pat = res.data[0]

          if (pat.service_time > 360) {
            setServiceTime(Math.floor(pat.service_time / 360) + ' años')
          } else if (pat.service_time > 30) {
            setServiceTime(Math.floor(pat.service_time / 30) + ' meses')
          } else {
            setServiceTime(pat.service_time + ' días')
          }

          setPatient(pat)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get population name
  useEffect(() => {
    if (!patient.population_id) return

    getPopulations({ id: patient.population_id })
      .then(res => {
        if (res.status === 200) {
          setPopulation(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [patient])

  // get eps name
  useEffect(() => {
    if (!patient.eps_id) return

    getEps({ id: patient.eps_id })
      .then(res => {
        if (res.status === 200) {
          setEps(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [patient])

  // get education level name
  useEffect(() => {
    if (!patient.education_level_id) return

    getEducationLevels({ id: patient.education_level_id })
      .then(res => {
        if (res.status === 200) {
          setEducationLevel(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [patient])

  // get rank name with ancestors
  useEffect(() => {
    if (!patient.rank_id) return

    getRanks({ id: patient.rank_id })
      .then(res => {
        if (res.status === 200) {
          setRank(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [patient])

  // get organigram name
  useEffect(() => {
    if (!patient.organigram_id) return

    getOrganigrams({ id: patient.organigram_id })
      .then(res => {
        if (res.status === 200) {
          setOrganigram(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [patient])

  // get selected reasons (motivos de consulta)
  useEffect(() => {
    if (!session.id) return

    getFromPivot({ action: 'reason_session', session_id: session.id })
      .then(res => {
        if (res.status === 200) {
          setSelectedReasons(
            res.data.map(item => {
              return {
                reason_id: parseInt(item.reason_id, 10)
              }
            })
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get selected precipitants
  useEffect(() => {
    if (!session.id) return

    getFromPivot({ action: 'precipitant_session', session_id: session.id })
      .then(res => {
        if (res.status === 200) {
          setSelectedPrecipitants(
            res.data.map(item => {
              return {
                precipitant_id: parseInt(item.precipitant_id, 10)
              }
            })
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get selected strategies
  useEffect(() => {
    if (!session.id) return

    getFromPivot({ action: 'strategy_session', session_id: session.id })
      .then(res => {
        if (res.status === 200) {
          setSelectedStrategies(
            res.data.map(item => {
              return {
                strategy_id: parseInt(item.strategy_id, 10)
              }
            })
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get selected response channels (canales de respuesta predominante)
  useEffect(() => {
    if (!session.id) return

    getFromPivot({ action: 'response_channel_session', session_id: session.id })
      .then(res => {
        if (res.status === 200) {
          setSelectedResponseChannels(
            res.data.map(item => {
              return {
                response_channel_id: parseInt(item.response_channel_id, 10)
              }
            })
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get selected diagnostic catalog
  useEffect(() => {
    if (!session.id) return

    getFromPivot({
      action: 'diagnostic_catalog_item_session',
      session_id: session.id
    })
      .then(res => {
        if (res.status === 200) {
          setSelectedDiagnostics(
            res.data.map(item => {
              return {
                diagnostic_catalog_item_id: parseInt(
                  item.diagnostic_catalog_item_id,
                  10
                )
              }
            })
          )
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get problematic
  useEffect(() => {
    if (!session.problematic_id) return

    getProblematics({ id: session.problematic_id })
      .then(res => {
        if (res.status === 200) {
          setProblematic(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get process (procedimiento)
  useEffect(() => {
    if (!session.process_id) return

    getProcesses({ id: session.process_id })
      .then(res => {
        if (res.status === 200) {
          setProcess(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // get process_result (resultado del procedimiento)
  useEffect(() => {
    if (!session.process_result_id) return

    getProcessResults({ id: session.process_result_id })
      .then(res => {
        if (res.status === 200) {
          setProcessResult(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [session])

  // redirecciona a remissions si es receptor y no está autorizado
  useEffect(() => {
    // aseguradora (id=6); sanidad militar (id=7)
    if (isBrowser() && rolesHasOneOf([6])) {
      navigate('/remissions/')
    } else if (isBrowser() && session.id) { // si estoy en el navegador y ya se obtuvo la sesión del backend
      // receptor (id=3); jefe de receptores (id=5)
      if (rolesHasOneOf([3, 5])) {
        // si la sesión tiene una remisión
        if (session.has_remission) {
          // si ya se obtuvo la remisión del backend
          if (remission.id) {
            // si la remisión aún no ha sido aprobada según la tabla remissions
            if (!remission.boss_check) {
              navigate('/remissions/')
            }
          }
        } else { // la sesión no tiene una remisión
          navigate('/remissions/')
        }
      }
    }
  }, [session, remission])

  if (!patient.id) {
    return <Layout withoutHeader>Cargando...</Layout>
  }

  // envía al backend la remisión
  function submitRemission () {
    putRemission(remission)
      .then(() => {
        toast.success('Remisión actualizada')
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }

  // envía al backend la aprobación para que la vean los receptores
  function checkRemission () {
    putCheckRemission(remission.id)
      .then(() => {
        setRemission({
          ...remission,
          boss_check: true,
          boss_check_time: moment().format('YYYY-MM-DD hh:mm:ss')
        })
        toast.success('Remisión aprobada')
      })
      .catch(err => {
        console.error(err.response ? err.response.data.message : err)
        toast.error(`${err}`)
      })
  }

  return (
    <Layout className='page-remit-session'>
      <div className='rs-header'>
        <div className='h1'>
          REPORTE DE REMISIÓN.{' '}
          <small style={{ color: 'var(--color3)' }}>
            Remite {remitter.fullname}.
          </small>{' '}
          <small>Recibe {receiver.fullname}</small>
        </div>

        <div
          style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}
        >
          <div style={{ marginRight: '1rem' }}>
            <strong>Aprobado: </strong> {!remission ? 'No' : 'Sí'}
          </div>
          <div>
            <strong>Autorizado:</strong>{' '}
            {moment
              .utc(remission.authorization_time)
              .local()
              .format('DD/MM/YYYY HH:mm')}
          </div>
          <div style={{ margin: '0 1rem' }}>
            <strong>Recibido:</strong>{' '}
            {moment
              .utc(remission.reception_time)
              .local()
              .format('DD/MM/YYYY HH:mm')}
          </div>
          <div>
            <strong>Duración:</strong>{' '}
            {moment
              .utc(
                moment
                  .duration(remission.call_duration, 'seconds')
                  .asMilliseconds()
              )
              .format('HH:mm:ss')}
          </div>
        </div>
      </div>

      <div className='rs-patient'>
        <div className='rs-patient__item'>
          <div>
            <strong>Nombre:</strong> {patient.name.toUpperCase()}
          </div>
          <div>
            <strong>Apellido:</strong> {patient.lastname.toUpperCase()}
          </div>
          <div>
            <strong>Población:</strong> {population.name}
          </div>
          <div>
            <strong>Género:</strong> {patient.gender ? 'Femenino' : 'Masculino'}
          </div>
        </div>

        <div className='rs-patient__item'>
          <div>
            <strong>Cédula:</strong> {patient.document_number}
          </div>
          <div>
            <strong>EPS:</strong> {eps.name}
          </div>
          <div>
            <strong>Edad:</strong>{' '}
            {Math.trunc(
              moment
                .duration(moment().diff(moment(patient.birthday)))
                .as('years')
            )}{' '}
            años
          </div>
          <div>
            <strong>Nivel de educación:</strong> {educationLevel.name}
          </div>
        </div>

        <div className='rs-patient__item'>
          <div>
            <strong>Estado civil:</strong> {patient.civil_status}
          </div>
          <div>
            <strong>Contactos de emergencia:</strong>{' '}
            {patient.emergency_contacts}
          </div>
        </div>

        <div className='rs-patient__item'>
          <div>
            <strong>Tiempo de servicio militar:</strong> {serviceTime}
          </div>
          <div>
            <strong>Rango:</strong> {rank.name},{' '}
            {rank.hierarchy
              ? `${rank.hierarchy.name} ${rank.hierarchy.military_force}`
              : ''}
          </div>
          <div>
            <strong>Ubicación:</strong> {patient.location_status}
          </div>
          <div>
            <strong>Detalles de la ubicación:</strong>{' '}
            {patient.location_details}
          </div>
        </div>

        <div className='rs-patient__item'>
          <div>
            <strong>Comandante:</strong> {patient.commander}
          </div>
          <div>
            <strong>Contingente:</strong> {patient.contingent}
          </div>
          <div>
            <strong>Organigrama:</strong> {organigram.name}
          </div>
        </div>
      </div>

      <div className='rs-session'>
        <div className='rs-session__item'>
          <h2>MOTIVO DE CONSULTA</h2>

          <div className='rs-session__item__content'>
            <ul className='selected-checkboxes'>
              {selectedReasons.map((selectedReason, index) => {
                const reason = reasons.find(
                  i => i.id === selectedReason.reason_id
                )

                if (!reason) {
                  return <li key={index}>-</li>
                }

                return <li key={reason.id}>{reason.name}</li>
              })}
            </ul>
          </div>
        </div>

        <div className='rs-session__item'>
          <h2>ÁREA DE AJUSTE</h2>

          <div className='rs-session__item__content'>
            <details className='accordion-mini'>
              <summary>
                <span>Precipitante</span>
                <img src={arrowIcon} alt='flecha' className='icon-arrow' />
              </summary>

              <div className='accordion-mini__content'>
                <ul className='selected-checkboxes'>
                  {selectedPrecipitants.map((selectedPrecipitant, index) => {
                    const precipitant = precipitants.find(
                      i => i.id === selectedPrecipitant.precipitant_id
                    )

                    if (!precipitant) {
                      return <li key={index}>-</li>
                    }

                    return <li key={precipitant.id}>{precipitant.name}</li>
                  })}
                </ul>
              </div>
            </details>

            <details className='accordion-mini'>
              <summary>
                <span>Estrategias empleadas anteriormente</span>
                <img src={arrowIcon} alt='flecha' className='icon-arrow' />
              </summary>

              <div className='accordion-mini__content'>
                <ul className='selected-checkboxes'>
                  {selectedStrategies.map((selectedStrategy, index) => {
                    const strategy = strategies.find(
                      i => i.id === selectedStrategy.strategy_id
                    )

                    if (!strategy) {
                      return <li key={index}>-</li>
                    }

                    return <li key={strategy.id}>{strategy.name}</li>
                  })}
                </ul>
              </div>
            </details>

            <details className='accordion-mini'>
              <summary>
                <span>Canal de respuesta predominante</span>
                <img src={arrowIcon} alt='flecha' className='icon-arrow' />
              </summary>

              <div className='accordion-mini__content'>
                <ul className='selected-checkboxes'>
                  {selectedResponseChannels.map(
                    (selectedResponseChannel, index) => {
                      const responseChannel = responseChannels.find(
                        i =>
                          i.id === selectedResponseChannel.response_channel_id
                      )

                      if (!responseChannel) {
                        return <li key={index}>-</li>
                      }

                      return (
                        <li key={responseChannel.id}>{responseChannel.name}</li>
                      )
                    }
                  )}
                </ul>
              </div>
            </details>
          </div>
        </div>

        <div className='rs-session__item'>
          <h2>IMPRESIÓN DIAGNÓSTICA</h2>

          <div className='rs-session__item__content'>
            <div>
              <h3>Impresión diagnóstica</h3>
              <ul className='selected-checkboxes'>
                {selectedDiagnostics.map((selectedDiagnostic, index) => {
                  const diagnostic = diagnostics.find(
                    i => i.id === selectedDiagnostic.diagnostic_catalog_item_id
                  )

                  if (!diagnostic) {
                    return <li key={index}>-</li>
                  }

                  return <li key={diagnostic.id}>{diagnostic.name}</li>
                })}
              </ul>
            </div>
            <div>
              <h3>Descripción de la impresión diagnóstica</h3>
              <div>{session.diagnostic_description}</div>
            </div>
            <div>
              <h3>Problemática</h3>
              <div>{problematic.name}</div>
            </div>
            <div>
              <h3>Descripción de la problemática</h3>
              <div>{session.problematic_description}</div>
            </div>
          </div>
        </div>

        <div className='rs-session__item'>
          <h2>ESTADO</h2>

          <div className='rs-session__item__content'>
            {/* update_remit_session (id=109) */}
            {canEdit ? (
              <label>
                Estado del consultante al momento de la remisión
                <textarea
                  value={remission.id ? remission.status_details : ''}
                  onChange={e => {
                    setRemission({
                      ...remission,
                      status_details: e.target.value
                    })
                  }}
                />
              </label>
            ) : (
              <div>{remission.id ? remission.status_details : ''}</div>
            )}
          </div>
        </div>

        <div className='rs-session__item'>
          <h2>PROCEDIMIENTOS</h2>

          <div className='rs-session__item__content'>
            <div>
              <h3>Procedimiento</h3>
              <div>{process.name}</div>
            </div>
            <div>
              <h3>Resultados del procedimiento</h3>
              <div>{processResult.name}</div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center', padding: '2rem 0' }}>
        {!canEdit ? null : (
          <button
            style={{
              display: 'inline-flex',
              fontSize: '1rem',
              padding: '0 1rem',
              backgroundColor: 'white',
              color: 'var(--color3)',
              fontWeight: 'bold'
            }}
            onClick={submitRemission}
          >
            GUARDAR
          </button>
        )}

        {/* check_remission (id=111) */}
        {!hasPermission(111) || remission.boss_check ? null : (
          <button
            style={{
              display: 'inline-flex',
              fontSize: '1rem',
              padding: '0 1rem',
              backgroundColor: 'white',
              color: 'var(--color3)',
              fontWeight: 'bold',
              marginLeft: '1rem'
            }}
            onClick={checkRemission}
          >
            APROBAR
          </button>
        )}
      </div>
    </Layout>
  )
}
