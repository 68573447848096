import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @param {Object} args - Argumentos a pasar en query
 * @returns {Promise}
 */
export function getEps (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`
  return axios.get(`${API('eps')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
